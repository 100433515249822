<template>
  <div class="classroom-state-edit">
    <div class="main">
      <div class="classroom-info">
        <div class="title">课室信息</div>
        <div class="content">
          <div class="c-item">{{ detail.name }}</div>
          <div class="c-item">地址:{{detail.province}}{{detail.city}}{{detail.area}}{{detail.buildingName}}{{detail.doorplateNumber}} </div>
          <div class="c-item area-people">
            <div>课室面积: {{ detail.acreage }}m²</div>
            <div>容纳人数: {{ detail.capacity }}位</div>
          </div>
          <div class="c-item area-people">
            <span>可用设备</span>
            <span class="text-button"
                  @click="showDialogEdit=true">查看</span>
          </div>
          <div class="c-item">课室说明: {{ detail.remark }}</div>
          <div class="c-item">
            <div>课室图片:</div>
            <div class="pictures">
              <div class="picture-item"
                   v-for="(item, index) in pictures"
                   :key="index">
                <div class="picture-item__content">
                  <van-image class="picture"
                             @click="pictureClick(index)"
                             fit="cover"
                             :src="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="base-info classroom-info"
           v-for="item in appointmentList">
        <div class="title">预约信息</div>
        <div class="content">
          <div class="c-item">
            <span class="name">使用时间:</span>
            <div class="value">
              <template v-for="(items,inds) in item.dates">
                <div> {{ items.borrowStartTime }}~{{ items.borrowEndTime}}<span v-if="item.dates.length-1!=inds">,</span></div>
              </template>
            </div>
          </div>
          <!-- <div class="c-item">使用时间: {{ item.borrowStartTime}} ~ {{ item.borrowEndTime}}</div> -->
          <div class="c-item"
               v-if="item.openDoorDatetime">开门时间: {{ item.openDoorDatetime }}</div>
          <div class="c-item">
            <span class="name">姓名: </span>
            {{ item.contactName }}
          </div>
          <div class="c-item">
            <span class="name">手机号码: </span>{{ item.contactMobileNumber }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-button v-if="appointment.status=='APPROVED'"
                  class="submit"
                  type="info"
                  round
                  :disabled="isClick"
                  @click="submit('USING')">确认使用</van-button>
      <van-button v-if="appointment.status=='USING'"
                  class="submit"
                  type="info"
                  :disabled="isClick"
                  @click="submit('EXPIRED')"
                  round>确认归还</van-button>
    </div>
    <van-dialog v-model="showDialog"
                show-cancel-button
                @confirm="dialogConfirm"
                title="请确认课室设备清单">
      <div class="dialog-conent">
        <van-checkbox v-model="isCheckedAll"
                      shape="square"
                      @click="toggleAll">全选</van-checkbox>
        <van-checkbox-group v-model="checkboxList"
                            @change="changeCheckbox"
                            ref="checkboxGroup">
          <template v-for="item in facility">
            <van-checkbox :name="item.id"
                          shape="square">
              <div class="conent-line">
                <span class="name">{{item.name}} X{{item.number}}</span>
                <div class="input"
                     @click.stop="()=>{}">
                  <van-field v-model="item.remark"
                             label-width="0"
                             placeholder="备注"></van-field>
                </div>

              </div>
            </van-checkbox>
          </template>
        </van-checkbox-group>
      </div>
    </van-dialog>

    <!-- 查看 -->
    <van-dialog v-model="showDialogEdit"
                :show-confirm-button="false"
                :show-cancel-button="true"
                title="请确认课室设备清单">
      <div class="dialog-conent"
           v-if="facility.length">
        <template v-for="item in facility">
          <div class="conent-item ">
            <span class="name">{{item.name}}</span>
            <span class="input">X{{item.number}}</span>
          </div>
        </template>
      </div>
      <div class="dialog-conent"
           style="padding:20px;color:#afafaf;text-align:center"
           v-else>暂无设备清单</div>
    </van-dialog>

  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomStateEdit.less";
import { SUCCESS_CODE } from "@/apis/notification";
export default {
  name: "classroomAppointmentDetail",
  data() {
    return {
      detail: {},
      appointment: {},
      activedTime: [],
      checkboxList: [],
      isCheckedAll: false,
      calendarShow: false,
      showDialog: false,
      showDialogEdit: false,
      contactName: "",
      contactMobileNumber: "",
      facility: [],
      isClick: false,
      appointmentList: []
    };
  },
  computed: {
    pictures() {
      return this.detail && this.detail.attachments && this.detail.attachments.map(item => item.url);
    }
  },
  watch: {
    checkboxList(newvalue, oldvalue) {
      this.isCheckedAll = this.checkboxList.length == this.facility.length;
    }
  },
  methods: {
    // /classroom_appointments/status
    // +++++++++++++++++
    init() {
      this.isClick = false;
      this.getClassroomDetail();
      // this.getClassroomAppointmentDetail();
      this.getClassroomAppointmentList();
    },
    //获取课室信息
    async getClassroomDetail() {
      let ret = await this.$api.getClassroomDetail({
        params: {
          useType: "MANAGER"
        },
        placeholder: {
          id: this.$route.query.classroomId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.detail = ret.data;
      if (ret.data.attachments.length) {
        this.detail.attachments = await this.getAppendixes(ret.data.attachments);
      }
      this.facility = ret.data.facilitys.map(val => {
        return { ...val, remark: "" };
      });
    },
    //获取解密图片url
    async getAppendixes(par) {
      let urls = par
        .map(val => {
          return val.attachmentUrl;
        })
        .join(",");
      let ret = await this.$api.getAppendixFilePath({ params: { urls } });
      return par.map((val, ind) => {
        return { ...val, url: ret.data[ind] };
      });
    },
    //获取课室预约详情
    async getClassroomAppointmentDetail() {
      let ret = await this.$api.getClassroomAppointmentDetail({
        placeholder: {
          id: this.$route.query.appointmentId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.appointment = ret.data;
    },
    // 课室设备清单 登记
    async postClassroomAppointmentsChecks() {
      let { classroomId, appointmentId } = this.$route.query;
      let params = {
        checkData: "",
        classroomId,
        checkType: this.status == "USING" ? "CHECK_IN" : "CHECK_OUT"
      };
      params.checkData = JSON.stringify(
        this.facility.map((val, index) => {
          return { ...val, check: this.checkboxList.includes(val.id) };
        })
      );
      let ret = await this.$api.postClassroomAppointmentsChecks({
        params,
        placeholder: {
          appointmentId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.putClassroomAppointmentsStatus();
    },
    // 修改课室预约状态
    async putClassroomAppointmentsStatus() {
      let params = {
        ids: [this.$route.query.appointmentId],
        status: this.status
      };
      let ret = await this.$api.putClassroomAppointmentsStatus({
        params
      });
      if (ret.code != SUCCESS_CODE) return;
      this.init();
    },
    //获取课室预约列表
    async getClassroomAppointmentList() {
      let classroomTime = this.$route.query.classroomTime.split(" ")[0];
      let params = {
        classroomId: this.$route.query.classroomId,
        startTime: classroomTime + " 00:00",
        endTime: classroomTime + " 23:59"
      };

      let ret = await this.$api.getClassroomAppointmentList({
        params
      });
      this.appointmentList = ret.data;
      console.log(12345, ret);
    },
    submit(status) {
      this.showDialog = true;
      this.status = status;
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll(this.isCheckedAll);
    },
    changeCheckbox() {
      // let isCheckedAll = this.checkboxList.length == this.facility.length;
      // this.$refs.checkboxGroup.toggleAll();
    },
    dialogConfirm() {
      this.isClick = true;
      this.postClassroomAppointmentsChecks();
    },
    pictureClick(index) {
      this.$utils.previewImage({
        images: this.pictures,
        startPosition: index
      });
    }
  },
  created() {
    this.init();
  }
};
</script>
